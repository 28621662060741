@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';


$spinnerDisplay: block;
$spinnerMargin: 0 auto;
$color: red;
$width: 20px!important;
$spinnerColor: red!important;


.spinner-css{
    display: $spinnerDisplay;
    margin: $spinnerMargin;
    width: $width;
    border-color: $spinnerColor;
    color: $color;
}
.Modal {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background-color: papayawhip;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rebeccapurple!important;
  }

  