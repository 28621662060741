
/* ScrollPanelDemo.css */

.scrollpanel-demo .p-scrollpanel p {
    padding: .5rem;
    line-height: 1.5;
    margin: 0;
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-wrapper {
    border-right: 10px solid var(--surface-b);
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-bar {
    background-color: var(--primary-color);
    opacity: 1;
    transition: background-color .2s;
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-bar:hover {
    background-color: #007ad9;
}

.scrollpanel-demo .p-scrollpanel.custombar2 .p-scrollpanel-wrapper {
    border-right: 10px solid var(--surface-b);
    border-bottom: 10px solid var(--surface-b);
}

.scrollpanel-demo .p-scrollpanel.custombar2 .p-scrollpanel-bar {
    background-color: var(--surface-d);
    border-radius: 0;
    opacity: 1;
    transition: background-color .2s;
}

.scrollpanel-demo .col-12 {
    padding: 2rem;
}
.pp{
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.p-image-preview{
    width: 500px;
    height:500px ;
   }
   .p-image-mask   {
      /* margin-top: 20%;
      padding-bottom: 20%; 
      margin-bottom: 20px; */
      padding-top: 20%;
      --maskbg: rgba(0, 0, 0, 0.1) !important;
   
   }   
   .p-image-mask{
       background-color: #007ad9;
   }    
   .p-image-action.p-link{
       background-color: #0D4D99 !important;
       margin-top: 50px;
   }